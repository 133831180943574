import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { HttpRequestBuilder } from 'app/services/http-builder.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'app/environments/environment';
import { SnackbarService } from 'app/services/snackbar.service';
import { RouteService } from 'app/services/route.service';
import { DropboxService } from 'app/services/dropbox.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-s3-connect-dialog',
  templateUrl: './s3-connect-dialog.component.html',
  styleUrls: ['./s3-connect-dialog.component.scss']
})
export class S3ConnectDialogComponent {
  S3Form: FormGroup;
  userId: string = '';
  subdomain: string;
  constructor(
    private fb: FormBuilder,
    private _cookie: CookieService,
    private _http: HttpRequestBuilder,
    private _snackBar: MatSnackBar,
    private _httpRequestBuilder: HttpRequestBuilder,
    private snackbarService: SnackbarService,
    private _routeService: RouteService,
    private _dropboxService: DropboxService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<S3ConnectDialogComponent>
) {}
ngOnInit() {
  this.subdomain = this._routeService.getSubdomainName();
  console.log(this.subdomain);
  const token = this._cookie.getAll()['access_token'];
  console.log(AuthUtils.extractUser(token));
  const { user_id } = AuthUtils.extractUser(token);
  this.S3Form = this.fb.group({
      access_key : ['', Validators.required],
      secret_key: ['', Validators.required],
      bucket: ['', Validators.required],
      host: ['', Validators.required],
      validity : [''],
  });

  this.userId = user_id;
  // this._http
  //     .get(environment.user_port, 'users/detail/' + user_id + '/')
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((res) => {
  //         console.log(res);
  //         this.profileForm.patchValue(res);
  //     });
}

close(){
  this.dialogRef.close();
}

connect() {
  if (this.S3Form.invalid) {
    // Handle form validation errors here if needed
    console.log("failed");
    return;
  }

  console.log("connected");

  const formValue = this.S3Form.value;

  this._httpRequestBuilder
    .post(environment.s3_port, 's3/connect/', {
      store_name: this.subdomain,
      store_slug: this.subdomain,
      aws_access_key: formValue.access_key,
      aws_secret_access_key: formValue.secret_key,
      aws_region: formValue.host,
      aws_s3_bucket_name: formValue.bucket,
    })
    .subscribe(
      (res) => {
        console.log(res);
        this.snackbarService.showSnackbar('Integration connected.');
        this.dialogRef.close(true);
      },
      (error) => {
        if (
          error.status === 400 &&
          error.error &&
          error.error.store_slug &&
          error.error.store_slug[0] ===
            'For this store settings already added'
        ) {
            this.snackbarService.showSnackbar('Integration connected.');
            this.dialogRef.close(true);
        } else {
          this.snackbarService.showSnackbar('Integration failed.');
        }
      }
    );
}



}
