<!-- Button -->
<button
    [matMenuTriggerFor]="userActions" class="flex flex-row items-center">
    <span class="relative">
        <img
            class="w-10 h-10 rounded-full"
            *ngIf="showAvatar && user.avatar"
            src="assets/icons/Avatar.png">
        <mat-icon
            *ngIf="!showAvatar || !user.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span
            class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
            [ngClass]="{'mr-px mb-px': !showAvatar || !user.avatar,
                          'bg-green-500': user.status === 'online',
                          'bg-amber-500': user.status === 'away',
                          'bg-red-500': user.status === 'busy',
                          'bg-gray-400': user.status === 'not-visible'}"
        ></span>
    </span>
    <mat-icon class="w-5 h-5">arrow_drop_down</mat-icon>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item class="flex flex-row">
        <div class="flex w-10 h-10 justify-center items-center m-2 text-white font-medium text-lg rounded-lg" style="padding:10px 8px; background-color: #019CDE;">
            {{ subDomainName | slice:0:2 }}
        </div>
        <div>
            <span class="flex flex-col leading-none">
                <span class="font-bold text-lg">{{subDomainName}}</span>
                <span class="mt-1.5 text-sm font-normal" style="color: var(--Colour-Paragraph---2, #9B9DA5);">{{user.email}}</span>
            </span>
        </div>

    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
        (click)="toProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span class="font-normal text-base" style="color: var(--Colour-Paragraph---1, #71737C);">Manage Account</span>
    </button>
    <button mat-menu-item>
        <mat-icon>store</mat-icon>
        <span class="font-normal text-base" style="color: var(--Colour-Paragraph---1, #71737C);">All Store</span>
    </button>
    <div class="mx-3">
        <button class="flex flex-row items-center justify-center text-white rounded-lg w-full" style="background-color: #7B00FF; padding: 14px 16px;">
            <mat-icon style="color: #FFFFFF;">add</mat-icon>
            <div class="font-medium text-lg">
                Create Store
            </div>
        </button>
    </div>
    <button mat-menu-item>
        <mat-icon>headset</mat-icon>
        <span class="font-normal text-base" style="color: var(--Colour-Paragraph---1, #71737C);">Help Center</span>
    </button>
    <button mat-menu-item
        (click)="toSelectStore()">
        <mat-icon>store</mat-icon>
        <span class="font-normal text-base" style="color: var(--Colour-Paragraph---1, #71737C);">Change Primary Store</span>
    </button>
    <!-- <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:dots-circle-horizontal'"></mat-icon>
        <span>Status</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'" style="color: var(--Colour-Red, #FF4852);"></mat-icon>
        <span class="font-normal text-base" style="color: var(--Colour-Red, #FF4852);">Log Out</span>
    </button>
</mat-menu>

<mat-menu
    class="user-status-menu"
    #userStatus="matMenu">
    <button
        mat-menu-item
        (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button
        mat-menu-item
        (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
