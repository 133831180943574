// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    domain: 'sellzzy.io',
    apiUrl: 'http://13.229.94.97',
    collection_port: '8007',
    product_port: '8007',
    store_port: '8005',
    s3_port: '8004',
    payment_port: '8006',
    auth_port: '8003',
    user_port: '8001',
    dropbox_port: '8010',
    stripePublicKey:
        'pk_test_51OWcTeLXqY0iEbxYVy4AmgRhg0XwJnAtg97tLVy44kFYi4LrTDo8lvUBpByq5kdXXpT9lXFtSIwlLctvQdjyNIbd00PFtkiOLI',
    stripeClientSecret:
        'seti_1OXkgmLXqY0iEbxYJnuiJ5yR_secret_PMTen0FOCVoHyIC2rCkfcWHUsKuPNKO',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
