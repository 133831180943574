<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="bg-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center w-full p-4 pl-6">
            <!-- Logo -->
            <div class="flex items-center justify-center">
                <img style="display: flex;
                    width: 123px;
                    height: 36px;
                    padding: 2px 4.896px 1.88px 1px;
                    justify-content: center;
                    align-items: center;" src="assets/images/logo/logo.svg">
            </div>
            <!-- Components -->
            <div class="flex items-center ml-auto">
                <!-- <notifications></notifications> -->
                <!-- <user [showAvatar]="false"></user> -->
            </div>
        </div>
        <!-- User -->
        <div class="flex flex-col items-center w-full p-4 text-black">
            <div class="w-full">  
                <mat-form-field class="w-full">
                    <img class="w-6 h-6 mr-3 rounded-full" src="assets/icons/Avatar.png">
                    <mat-select placeholder={{subdomain}}>
                    </mat-select>
                </mat-form-field>
            </div>

            
            <div class="font-normal text-base w-full justify-start" style="color: var(--Colour-Paragraph---2, #9B9DA5);
            text-transform: uppercase;">
                Menu
            </div>
            <!-- <div class="relative w-24 h-24">
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user?.avatar"
                    [src]="user?.avatar"
                    alt="User avatar">
                <mat-icon
                    class="icon-size-24"
                    *ngIf="!user.avatar"
                    [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
            </div> -->
            <!-- <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
            </div> -->
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter style="position: fixed; bottom: 0; width: 100%; z-index: 1000;">
        <!-- <div class="flex flex-col items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                    <g clip-path="url(#clip0_1333_1454)">
                      <path d="M1.79227 29.3359L5.89082e-08 30.0958C-0.000117129 30.55 0.174609 31.0008 0.515508 31.3404L28.7632 59.4893C29.0929 59.8179 29.6591 59.998 29.9916 59.9996C30.3293 60.0011 30.9069 59.8233 31.2423 59.489L59.4852 31.3224C59.8252 30.9832 59.9998 30.5336 60.0002 30.0802L58.4419 29.3361C57.5729 29.3361 49.5022 29.3361 39.6961 29.3361L29.1809 32.7304L20.2876 29.3361C11.0632 29.3359 3.38016 29.3359 1.79227 29.3359Z" fill="#FFB739"/>
                      <path d="M59.9995 30.0803C59.9995 30.5332 59.8252 30.9828 59.4845 31.3221L31.2418 59.4888C30.9095 59.8211 30.337 59.9991 30 59.9991V32.4657L39.696 29.3359H58.4418L59.9995 30.0803Z" fill="#FF9441"/>
                      <path d="M20.2874 29.3359L30 59.9991L39.6958 29.3359H20.2874Z" fill="#FF9441"/>
                      <path d="M39.6959 29.3359L30 59.9991V29.3359H39.6959Z" fill="#FF7149"/>
                      <path d="M59.7055 29.1046L50.3039 15.0184C50.1738 14.8236 50.0062 14.6581 49.8132 14.5304L48.9611 14.9396L31.6264 15.0477L30 14.2373L28.6856 15.0659L11.39 15.1737L10.1831 14.5321C9.99152 14.6595 9.82453 14.8244 9.69469 15.0193L0.293789 29.1233C0.0958594 29.4202 0 29.759 0 30.0959C3.40559 30.095 55.6015 30.0813 60 30.0801C60.0002 29.7423 59.9041 29.4023 59.7055 29.1046Z" fill="#FFD17E"/>
                      <path d="M59.9995 30.0805C57.8202 30.0805 43.9012 30.0852 30 30.0887V14.2373L31.6269 15.0472L48.9612 14.9395L49.8132 14.5298C50.0064 14.6586 50.1737 14.8236 50.3037 15.0179L59.7059 29.1042C59.9047 29.4028 60.0007 29.7421 59.9995 30.0805Z" fill="#FFB739"/>
                      <path d="M11.1554 14.2373C10.8043 14.2373 10.4675 14.3429 10.1835 14.5319L20.5963 30.0905H20.5975L29.9994 14.2373H11.1554Z" fill="#FFB739"/>
                      <path d="M39.4059 30.0855L49.8129 14.53C49.5294 14.3422 49.1936 14.2373 48.8436 14.2373H29.9994L39.3984 30.0856H39.4059V30.0855Z" fill="#FF9441"/>
                      <path d="M19.5028 42.3418C16.5643 42.3418 14.1736 39.9512 14.1736 37.0127C14.1736 36.0419 13.3866 35.2549 12.4158 35.2549C11.445 35.2549 10.658 36.0419 10.658 37.0127C10.658 39.9364 8.28175 42.3418 5.32886 42.3418C4.35808 42.3418 3.57104 43.1288 3.57104 44.0996C3.57104 45.0704 4.35808 45.8574 5.32886 45.8574C8.26733 45.8574 10.658 48.248 10.658 51.1865C10.658 52.1573 11.445 52.9443 12.4158 52.9443C13.3866 52.9443 14.1736 52.1573 14.1736 51.1865C14.1736 48.2631 16.5496 45.8574 19.5028 45.8574C20.4736 45.8574 21.2606 45.0704 21.2606 44.0996C21.2606 43.1288 20.4736 42.3418 19.5028 42.3418Z" fill="#FFD17E"/>
                      <path d="M46.4448 7.08691C43.5064 7.08691 41.1157 4.69629 41.1157 1.75781C41.1157 0.787031 40.3287 0 39.3579 0C38.3871 0 37.6001 0.787031 37.6001 1.75781C37.6001 4.69629 35.2096 7.08691 32.271 7.08691C31.3002 7.08691 30.5132 7.87395 30.5132 8.84473C30.5132 9.81551 31.3002 10.6025 32.271 10.6025C35.2095 10.6025 37.6001 12.9932 37.6001 15.9316C37.6001 16.9024 38.3871 17.6895 39.3579 17.6895C40.3287 17.6895 41.1157 16.9024 41.1157 15.9316C41.1157 12.9932 43.5062 10.6025 46.4448 10.6025C47.4156 10.6025 48.2026 9.81551 48.2026 8.84473C48.2026 7.87395 47.4156 7.08691 46.4448 7.08691Z" fill="#FFB739"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1333_1454">
                        <rect width="60" height="60" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
            </div>
            
              <div class="font-normal text-base mt-3" style="color: var(--Paragraph, #676D7C);">
                Unlock Premium Features !!!
              </div>

              <div>
                <button class="font-medium text-base px-6 py-3 mt-4" style="color: var(--Colour-White, #FFF); border-radius: var(--Redius-Redius---2, 8px);
                background: var(--Colour-Yellow, #FFA820);">
                    Upgrade Today
                </button>
              </div>
        </div> -->
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
    class="relative flex flex-0 items-center w-full h-20 px-6 py-5 md:px-6 z-49 dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <form>  
        <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none">
                <!-- <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg> -->
            </div>

            <input class="search" type="search" placeholder="Search">
        </div>
    </form>
        <!-- Navigation toggle button -->
        <!-- <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button> -->
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <button mat-icon-button>
                <mat-icon>notifications</mat-icon>
            </button>
            <user [showAvatar]="true"></user>
            <!-- <languages></languages> -->
            <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            <!-- <button
                class="lg:hidden"
                mat-icon-button
            >
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
 
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
