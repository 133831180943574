<div class="flex flex-col justify-between w-full">
    <!-- <signup-header> </signup-header> -->
    <div class="flex flex-col justify-center items-center w-full h-full" style="width: 372px; height: 353px;">
    <div class="flex justify-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="244" height="152" viewBox="0 0 244 152" fill="none">
            <path d="M125.969 45.91C127.826 41.5551 133.999 41.5551 135.856 45.91L144.789 66.8553C145.72 69.0393 147.788 70.5247 150.155 70.7098L173.071 72.5016C177.874 72.8771 179.795 78.8953 176.098 81.984L158.857 96.3883C156.991 97.9473 156.174 100.432 156.752 102.794L162.057 124.486C163.19 129.116 158.181 132.819 154.086 130.38L134.175 118.518C132.165 117.321 129.661 117.321 127.651 118.518L107.739 130.38C103.644 132.819 98.6359 129.116 99.7683 124.486L105.073 102.794C105.651 100.432 104.835 97.9473 102.968 96.3883L85.7273 81.984C82.0302 78.8952 83.9512 72.8771 88.7542 72.5016L111.67 70.7098C114.038 70.5247 116.106 69.0393 117.037 66.8553L125.969 45.91Z" stroke="#0E0E1E"/>
            <path d="M127.958 49.0212C129.964 44.1966 136.799 44.1966 138.806 49.0212L147.348 69.5583C148.194 71.5923 150.106 72.982 152.302 73.158L174.474 74.9355C179.682 75.3531 181.794 81.8531 177.826 85.2525L160.933 99.7226C159.26 101.156 158.53 103.404 159.041 105.547L164.202 127.183C165.414 132.265 159.885 136.283 155.426 133.559L136.444 121.965C134.564 120.817 132.2 120.817 130.32 121.965L111.338 133.559C106.878 136.283 101.349 132.265 102.562 127.183L107.722 105.547C108.234 103.404 107.503 101.156 105.83 99.7226L88.9377 85.2525C84.9693 81.8531 87.0813 75.3531 92.2898 74.9355L114.461 73.158C116.657 72.982 118.57 71.5923 119.416 69.5583L127.958 49.0212Z" fill="#7B00FF"/>
            <path d="M185.418 87.716C186.176 85.5333 189.263 85.5333 190.022 87.716L192.964 96.1809C193.436 97.5388 194.704 98.4595 196.141 98.4888L205.101 98.6714C207.411 98.7185 208.365 101.654 206.524 103.05L199.382 108.464C198.237 109.333 197.753 110.823 198.169 112.199L200.764 120.777C201.433 122.988 198.936 124.803 197.039 123.483L189.683 118.364C188.503 117.543 186.937 117.543 185.757 118.364L178.401 123.483C176.504 124.803 174.007 122.988 174.676 120.777L177.271 112.199C177.687 110.823 177.203 109.333 176.057 108.464L168.916 103.05C167.075 101.654 168.029 98.7185 170.339 98.6714L179.299 98.4888C180.736 98.4595 182.003 97.5388 182.475 96.1809L185.418 87.716Z" stroke="#0E0E1E"/>
            <path d="M186.414 89.4883C187.329 86.8579 191.049 86.8579 191.963 89.4883L194.905 97.9532C195.309 99.1136 196.392 99.9004 197.62 99.9255L206.58 100.108C209.364 100.165 210.514 103.703 208.294 105.385L201.153 110.799C200.174 111.542 199.76 112.815 200.116 113.991L202.711 122.568C203.518 125.234 200.508 127.42 198.222 125.83L190.866 120.711C189.858 120.009 188.519 120.009 187.511 120.711L180.155 125.83C177.869 127.42 174.86 125.234 175.666 122.568L178.261 113.991C178.617 112.815 178.203 111.542 177.224 110.799L170.083 105.385C167.864 103.703 169.013 100.165 171.797 100.108L180.757 99.9255C181.986 99.9004 183.068 99.1136 183.472 97.9532L186.414 89.4883Z" fill="#7B00FF"/>
            <path d="M70.9305 83.3097C71.6892 81.1271 74.776 81.1271 75.5346 83.3097L78.477 91.7746C78.9491 93.1326 80.2163 94.0533 81.6537 94.0826L90.6135 94.2652C92.9238 94.3123 93.8777 97.248 92.0363 98.644L84.8949 104.058C83.7493 104.927 83.2653 106.416 83.6816 107.793L86.2767 116.37C86.9458 118.582 84.4485 120.396 82.5518 119.077L75.1958 113.958C74.0158 113.137 72.4493 113.137 71.2693 113.958L63.9133 119.077C62.0166 120.396 59.5193 118.582 60.1885 116.37L62.7835 107.793C63.1999 106.416 62.7158 104.927 61.5702 104.058L54.4288 98.644C52.5875 97.248 53.5413 94.3123 55.8516 94.2652L64.8115 94.0826C66.2488 94.0533 67.5161 93.1326 67.9881 91.7746L70.9305 83.3097Z" stroke="#0E0E1E"/>
            <path d="M71.8635 85.0831C72.7778 82.4526 76.4979 82.4526 77.4122 85.0831L80.3546 93.548C80.758 94.7084 81.8409 95.4952 83.0691 95.5202L92.029 95.7028C94.8132 95.7595 95.9628 99.2975 93.7436 100.98L86.6023 106.394C85.6233 107.136 85.2097 108.409 85.5654 109.585L88.1605 118.163C88.9669 120.828 85.9573 123.015 83.6715 121.424L76.3155 116.306C75.3071 115.604 73.9686 115.604 72.9602 116.306L65.6042 121.424C63.3183 123.015 60.3087 120.828 61.1151 118.163L63.7102 109.585C64.066 108.409 63.6523 107.136 62.6734 106.394L55.532 100.98C53.3129 99.2975 54.4624 95.7595 57.2467 95.7028L66.2065 95.5202C67.4348 95.4952 68.5177 94.7084 68.9211 93.548L71.8635 85.0831Z" fill="#7B00FF"/>
            <path d="M9.04475 108.544C9.366 107.619 10.6731 107.619 10.9943 108.544L12.0281 111.518C12.1698 111.925 12.5503 112.202 12.9819 112.211L16.1299 112.275C17.1082 112.295 17.5121 113.538 16.7324 114.129L14.2233 116.031C13.8793 116.292 13.734 116.739 13.859 117.152L14.7707 120.166C15.0541 121.103 13.9967 121.871 13.1935 121.312L10.609 119.514C10.2547 119.267 9.78437 119.267 9.43007 119.514L6.84554 121.312C6.0424 121.871 4.98498 121.103 5.26831 120.166L6.1801 117.152C6.30509 116.739 6.15976 116.292 5.8158 116.031L3.30668 114.129C2.52698 113.538 2.93088 112.295 3.90912 112.275L7.05718 112.211C7.48873 112.202 7.86921 111.925 8.01093 111.518L9.04475 108.544Z" fill="#7B00FF"/>
            <path d="M31.3733 59.9512C31.5957 59.3114 32.5006 59.3114 32.723 59.9512L33.4387 62.0102C33.5368 62.2925 33.8002 62.4839 34.099 62.49L36.2784 62.5344C36.9557 62.5482 37.2353 63.4088 36.6955 63.818L34.9584 65.135C34.7203 65.3155 34.6197 65.6252 34.7062 65.9112L35.3375 67.9977C35.5336 68.646 34.8015 69.1779 34.2455 68.791L32.4562 67.5459C32.2109 67.3752 31.8854 67.3752 31.6401 67.5459L29.8508 68.791C29.2948 69.1779 28.5627 68.646 28.7588 67.9977L29.3901 65.9112C29.4766 65.6252 29.376 65.3155 29.1379 65.135L27.4008 63.818C26.861 63.4088 27.1406 62.5482 27.8179 62.5344L29.9973 62.49C30.2961 62.4839 30.5595 62.2925 30.6576 62.0102L31.3733 59.9512Z" fill="#7B00FF"/>
            <path d="M63.6829 15.8936C63.9053 15.2538 64.8102 15.2538 65.0326 15.8936L65.7483 17.9526C65.8464 18.2349 66.1098 18.4263 66.4086 18.4324L68.588 18.4768C69.2652 18.4906 69.5449 19.3512 69.0051 19.7604L67.268 21.0774C67.0299 21.2579 66.9292 21.5676 67.0158 21.8536L67.647 23.9401C67.8432 24.5884 67.1111 25.1203 66.5551 24.7334L64.7658 23.4883C64.5205 23.3176 64.1949 23.3176 63.9496 23.4883L62.1603 24.7334C61.6043 25.1203 60.8723 24.5884 61.0684 23.9401L61.6997 21.8536C61.7862 21.5676 61.6856 21.2579 61.4474 21.0774L59.7104 19.7604C59.1706 19.3512 59.4502 18.4906 60.1274 18.4768L62.3069 18.4324C62.6056 18.4263 62.869 18.2349 62.9672 17.9526L63.6829 15.8936Z" fill="#7B00FF"/>
            <path d="M88.6487 45.2647C88.8711 44.6249 89.776 44.6249 89.9984 45.2647L90.7141 47.3237C90.8122 47.606 91.0756 47.7974 91.3744 47.8034L93.5538 47.8479C94.2311 47.8617 94.5107 48.7223 93.9709 49.1315L92.2338 50.4485C91.9957 50.629 91.8951 50.9387 91.9816 51.2247L92.6128 53.3112C92.809 53.9595 92.0769 54.4914 91.5209 54.1045L89.7316 52.8594C89.4863 52.6887 89.1607 52.6887 88.9155 52.8594L87.1262 54.1045C86.5701 54.4914 85.8381 53.9595 86.0342 53.3112L86.6655 51.2247C86.752 50.9387 86.6514 50.629 86.4133 50.4485L84.6762 49.1315C84.1364 48.7223 84.416 47.8617 85.0933 47.8479L87.2727 47.8034C87.5714 47.7974 87.8349 47.606 87.933 47.3237L88.6487 45.2647Z" fill="#7B00FF"/>
            <path d="M120.524 13.562C120.648 13.2065 121.15 13.2065 121.274 13.562L121.672 14.7059C121.726 14.8627 121.872 14.969 122.038 14.9724L123.249 14.9971C123.625 15.0047 123.781 15.4828 123.481 15.7102L122.516 16.4419C122.384 16.5421 122.328 16.7142 122.376 16.8731L122.726 18.0322C122.835 18.3924 122.429 18.6879 122.12 18.473L121.126 17.7813C120.989 17.6864 120.809 17.6864 120.672 17.7813L119.678 18.473C119.369 18.6879 118.963 18.3924 119.072 18.0322L119.422 16.8731C119.47 16.7142 119.415 16.5421 119.282 16.4419L118.317 15.7102C118.017 15.4828 118.173 15.0047 118.549 14.9971L119.76 14.9724C119.926 14.969 120.072 14.8627 120.127 14.7059L120.524 13.562Z" fill="#7B00FF"/>
            <path d="M161.27 2.15716C161.517 1.44623 162.522 1.44623 162.77 2.15716L163.565 4.44497C163.674 4.7586 163.967 4.97124 164.298 4.97801L166.72 5.02735C167.473 5.04269 167.783 5.9989 167.183 6.45362L165.253 7.91691C164.989 8.1175 164.877 8.46157 164.973 8.77937L165.675 11.0977C165.892 11.8181 165.079 12.4091 164.461 11.9792L162.473 10.5957C162.201 10.4061 161.839 10.4061 161.566 10.5957L159.578 11.9792C158.96 12.4091 158.147 11.8181 158.365 11.0977L159.066 8.77937C159.162 8.46157 159.051 8.1175 158.786 7.91691L156.856 6.45362C156.256 5.9989 156.567 5.04269 157.319 5.02735L159.741 4.97801C160.073 4.97124 160.366 4.7586 160.475 4.44497L161.27 2.15716Z" fill="#7B00FF"/>
            <path d="M185.936 33.8608C186.281 32.8655 187.689 32.8655 188.035 33.8608L189.148 37.0638C189.301 37.5029 189.711 37.8006 190.175 37.81L193.566 37.8791C194.619 37.9006 195.054 39.2393 194.214 39.8759L191.512 41.9245C191.142 42.2053 190.985 42.687 191.12 43.1319L192.102 46.3776C192.407 47.3861 191.268 48.2135 190.403 47.6116L187.62 45.6748C187.239 45.4093 186.732 45.4093 186.35 45.6748L183.567 47.6116C182.702 48.2135 181.563 47.3861 181.869 46.3776L182.851 43.1319C182.985 42.687 182.829 42.2053 182.458 41.9245L179.756 39.8759C178.916 39.2393 179.351 37.9006 180.405 37.8791L183.795 37.81C184.26 37.8006 184.67 37.5029 184.822 37.0638L185.936 33.8608Z" fill="#7B00FF"/>
            <path d="M227.507 59.7355C227.705 59.1668 228.509 59.1668 228.707 59.7355L229.343 61.5657C229.43 61.8166 229.664 61.9868 229.93 61.9922L231.867 62.0316C232.469 62.0439 232.718 62.8089 232.238 63.1727L230.694 64.3433C230.482 64.5038 230.393 64.779 230.47 65.0333L231.031 66.8879C231.205 67.4642 230.554 67.937 230.06 67.5931L228.47 66.4863C228.252 66.3346 227.962 66.3346 227.744 66.4863L226.154 67.5931C225.659 67.937 225.009 67.4642 225.183 66.8879L225.744 65.0333C225.821 64.779 225.732 64.5038 225.52 64.3433L223.976 63.1727C223.496 62.8089 223.745 62.0439 224.347 62.0316L226.284 61.9922C226.549 61.9868 226.784 61.8166 226.871 61.5657L227.507 59.7355Z" fill="#7B00FF"/>
            <path d="M233.006 109.278C233.327 108.354 234.634 108.354 234.955 109.278L235.989 112.252C236.131 112.66 236.511 112.936 236.943 112.945L240.091 113.009C241.069 113.029 241.473 114.272 240.693 114.863L238.184 116.766C237.84 117.026 237.695 117.474 237.82 117.887L238.732 120.901C239.015 121.837 237.958 122.605 237.154 122.047L234.57 120.248C234.216 120.002 233.745 120.002 233.391 120.248L230.806 122.047C230.003 122.605 228.946 121.837 229.229 120.901L230.141 117.887C230.266 117.474 230.121 117.026 229.777 116.766L227.268 114.863C226.488 114.272 226.892 113.029 227.87 113.009L231.018 112.945C231.45 112.936 231.83 112.66 231.972 112.252L233.006 109.278Z" fill="#7B00FF"/>
            <rect x="29.8457" y="71.2266" width="4.4058" height="23.4976" fill="url(#paint0_linear_71_1672)"/>
            <rect x="60.6865" y="27.1689" width="7.343" height="36.715" fill="url(#paint1_linear_71_1672)"/>
            <rect x="157.613" y="13.9521" width="8.81159" height="36.715" fill="url(#paint2_linear_71_1672)"/>
            <rect x="225.169" y="69.7588" width="5.8744" height="24.9662" fill="url(#paint3_linear_71_1672)"/>
            <defs>
              <linearGradient id="paint0_linear_71_1672" x1="32.0486" y1="71.2266" x2="32.0486" y2="94.7241" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7300FF" stop-opacity="0"/>
                <stop offset="0.525" stop-color="#6F00FF" stop-opacity="0.11"/>
                <stop offset="1" stop-color="#7B00FF" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_71_1672" x1="64.358" y1="27.1689" x2="64.358" y2="63.8839" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7300FF" stop-opacity="0"/>
                <stop offset="0.525" stop-color="#6F00FF" stop-opacity="0.11"/>
                <stop offset="1" stop-color="#7B00FF" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint2_linear_71_1672" x1="162.019" y1="13.9521" x2="162.019" y2="50.6671" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7300FF" stop-opacity="0"/>
                <stop offset="0.525" stop-color="#6F00FF" stop-opacity="0.11"/>
                <stop offset="1" stop-color="#7B00FF" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint3_linear_71_1672" x1="228.106" y1="69.7588" x2="228.106" y2="94.725" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7300FF" stop-opacity="0"/>
                <stop offset="0.525" stop-color="#6F00FF" stop-opacity="0.11"/>
                <stop offset="1" stop-color="#7B00FF" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>
    </div>
    <div class="font-semibold text-3xl flex justify-center">
        Congratulations
    </div>
    <div class="font-normal text-lg flex justify-center text-center">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
    </div>
    <div class="mt-8">
        <button class="w-full font-medium text-lg text-white" style="border-radius: var(--Redius-Redius---2, 8px);
        background: var(--Colour-Primary, #7B00FF); padding: 16px 10px;"
        routerLink="['dashboard']">
            Get Started
        </button>
    </div>
</div>
</div>