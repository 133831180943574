import { Component, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'app/environments/environment';
import { StoreService } from 'app/services/store.service';

@Component({
    selector: 'app-store-select-dialog',
    templateUrl: './store-select-dialog.component.html',
    styleUrls: ['./store-select-dialog.component.scss'],
})
export class StoreSelectDialogComponent {
    @Output() storeSelected = new EventEmitter<any>();
    stores = [];
    primaryStore = undefined;
    constructor(
        public dialogRef: MatDialogRef<StoreSelectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _storeService: StoreService
    ) {
        this.stores = this.data.stores;
        this.primaryStore = this.stores.find((store) => {
            if (store.is_store_primary === true) {
                return store;
            }
        });
    }

    onClearClick(): void {
        this.dialogRef.close();
    }

    onStoreHover(isHovered: boolean, store: any): void {
        store.isHovered = isHovered;
    }

    onStoreClick(store: any): void {
        console.log('onStoreClick');
        store.is_store_primary = true;
        if (this.primaryStore === undefined || this.primaryStore === null) {
            this._storeService.setPrimaryStore(store).subscribe({
                next: (res) => {
                    setTimeout(() => {
                        this.storeSelected.emit(store);
                        this.dialogRef.close();
                    }, 200);
                },
                error: (error) => {
                    console.log(error);
                    this.dialogRef.close();
                },
            });
        } else {
            let ok = confirm(
                'Are you sure you want to change your primary store?'
            );
            if (ok) {
                this._storeService.changePrimaryStore(store).subscribe({
                    next: (res) => {
                        setTimeout(() => {
                            this.storeSelected.emit(store);
                            this.dialogRef.close();
                        }, 200);
                    },
                    error: (error) => {
                        console.log(error);
                        this.dialogRef.close();
                    },
                });
            }
        }
    }
    showStoreUrl(store) {
        return `${store.store_slug}.${environment.domain}`;
    }
}
