import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'app/environments/environment';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpRequestBuilder } from './http-builder.service';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    constructor(
        private _httpclient: HttpClient,
        private _httpBuilder: HttpRequestBuilder) {}

    setPrimaryStore(store) {
        return this._httpclient
            .post(
                `${environment.apiUrl}:${environment.store_port}/store/set_primary_store/`,
                {
                    store_id: store.id,
                }
            )
            .pipe(
                switchMap((res) => {
                    return of(res);
                })
            );
    }
    changePrimaryStore(store) {
        return this._httpclient
            .post(
                `${environment.apiUrl}:${environment.store_port}/store/change_primary_store/`,
                {
                    store_id: store.id,
                }
            )
            .pipe(
                switchMap((res) => {
                    return of(res);
                })
            );
    }

    getStaffs(storeSlug: string, params: any = null): Observable<any> {
        const data = {
            "store_slug": storeSlug
        }
        return this._httpBuilder.post("8005", "store/staffs/", data).pipe(
          switchMap((data: any[]) => {
            console.log('data', data);
            return of(data.map(staff => ({
              id: staff.id,
              name: staff.first_name + ' ' + staff.last_name,
              role: staff.roles ? staff.roles[0]['role_name'] : null,
              status: staff.is_active? 'Active': 'Inactive'
            })));
          }),
          catchError(error => {
            console.error(error);
            return of([]); 
          })
        )
      }

    updateStore(data, store_id) {
        return this._httpclient
            .put(
                `${environment.apiUrl}:${environment.store_port}/store/${store_id}/update/`,
                data
            )
            .pipe(
                switchMap((res) => {
                    return of(res);
                })
            );
    }

    getStoreBySlug(store_slug) {
        return this._httpclient
            .get(
                `${environment.apiUrl}:${environment.store_port}/store?store_slug=${store_slug}`
            )
            .pipe(
                switchMap((res) => {
                    return of(res);
                })
            );
    }
}
