import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { StoreGuard } from './core/store/store.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        // canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'create-store',
                loadChildren: () =>
                    import(
                        'app/modules/stores/create-store/create-store.module'
                    ).then((m) => m.CreateStoreModule),
            },
            {
                path: 'current-revenue',
                loadChildren: () =>
                    import(
                        'app/components/current-revenue/current-revenue.module'
                    ).then((m) => m.CurrentRevenueModule),
            },
        ],
    },
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'create-store',
                loadChildren: () =>
                    import(
                        'app/modules/stores/create-store/create-store.module'
                    ).then((m) => m.CreateStoreModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'classy',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Select store route

    {
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        path: 'select-store',
        loadChildren: () =>
            import('app/components/select-store/select-store.module').then(
                (m) => m.SelectStoreModule
            ),
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard, StoreGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'example',
                loadChildren: () =>
                    import('app/modules/admin/example/example.module').then(
                        (m) => m.ExampleModule
                    ),
            },
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('app/modules/admin/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'connect',
                loadChildren: () =>
                    import('app/modules/admin/connect/connect.module').then(
                        (m) => m.ConnectModule
                    ),
            },
            {
                path: 'customers',
                loadChildren: () =>
                    import('app/modules/admin/customers/customers.module').then(
                        (m) => m.CustomersModule
                    ),
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
            {
                path: 'admin/products',
                loadChildren: () =>
                    import(
                        'app/modules/landing/product-list/product-list.module'
                    ).then((m) => m.ProductListModule),
            },
            {
                path: 'orders',
                loadChildren: () =>
                    import('app/modules/landing/orders/orders.module').then(
                        (m) => m.OrdersModule
                    ),
            },
            {
                path: 'staff-permission',
                loadChildren: () =>
                    import(
                        'app/modules/landing/staff-permissions/staff-permissions.module'
                    ).then((m) => m.StaffPermissionsModule),
            },
            {
                path: 'payment-gateway',
                loadChildren: () =>
                    import(
                        'app/modules/admin/payment-gateway/payment-gateway.module'
                    ).then((m) => m.PaymentGatewayModule),
            },
            {
                path: 'cloud-spaces',
                loadChildren: () =>
                    import(
                        'app/modules/admin/cloud-spaces/cloud-spaces.module'
                    ).then((m) => m.CloudSpacesModule),
            },
            {
                path: 'buy-plan',
                loadChildren: () =>
                    import('app/modules/admin/buy-plan/buy-plan.module').then(
                        (m) => m.BuyPlanModule
                    ),
            },
            {
                path: 'billing',
                loadChildren: () =>
                    import('app/modules/admin/billing/billing.module').then(
                        (m) => m.BillingModule
                    ),
            },
            {
                path: 'checkout',
                loadChildren: () =>
                    import('app/modules/admin/checkout/checkout.module').then(
                        (m) => m.CheckoutModule
                    ),
            },
            {
                path: 'rewards',
                loadChildren: () =>
                    import('app/modules/admin/rewards/rewards.module').then(
                        (m) => m.RewardsModule
                    ),
            },
            {
                path: 'create-bonus',
                loadChildren: () =>
                    import(
                        'app/modules/admin/create-bonus/create-bonus.module'
                    ).then((m) => m.CreateBonusModule),
            },
            {
                path: 'news-update',
                loadChildren: () =>
                    import(
                        'app/modules/admin/news-update/news-update.module'
                    ).then((m) => m.NewsUpdateModule),
            },
            {
                path: 'news-create',
                loadChildren: () =>
                    import(
                        'app/modules/admin/news-create/news-create.module'
                    ).then((m) => m.NewsCreateModule),
            },
            {
                path: 'add',
                loadChildren: () =>
                    import('app/modules/admin/customers/add/add.module').then(
                        (m) => m.AddModule
                    ),
            },
            {
                path: 'collection',
                loadChildren: () =>
                    import(
                        'app/modules/landing/collection/collection.module'
                    ).then((m) => m.CollectionModule),
            },
            {
                path: 'add-product',
                loadChildren: () =>
                    import(
                        'app/modules/landing/add-product/add-product.module'
                    ).then((m) => m.AddProductModule),
            },
            {
                path: 'subscriptions',
                loadChildren: () =>
                    import(
                        'app/modules/landing/subscriptions/subscriptions.module'
                    ).then((m) => m.SubscriptionsModule),
            },
            {
                path: 'create-bundle',
                loadChildren: () =>
                    import(
                        'app/modules/landing/create-bundle/create-bundle.module'
                    ).then((m) => m.CreateBundleModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'dashboard',
    },
];
