/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:view-grid',
        link: '/dashboard',
    },
    {
        id: 'connect',
        title: 'Connect',
        type: 'basic',
        icon: 'heroicons_outline:cube-transparent',
        link: '/connect',
    },
    {
        id: 'settings',
        title: 'Settings',
        type: 'collapsable',
        icon: 'heroicons_outline:cog',
        children: [
            {
                id: 'cloud-spaces',
                title: 'Cloud Spaces',
                type: 'basic',
                icon: 'heroicons_outline:cloud',
                link: '/cloud-spaces'
            },
            {
                id: 'payment-gateway',
                title: 'Payment Gateway',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: '/payment-gateway',
            },
            {
                id: 'billing',
                title: 'Billing',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-list',
                link: '/billing',
            },
            {
                id: 'buy-plan',
                title: 'Buy Plan',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/buy-plan',
            },
            {
                id: 'staff-permission',
                title: 'Staff Permission',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/staff-permission'
            },
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];