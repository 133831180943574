import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';

// Imports for translation
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { RewardsComponent } from './modules/admin/rewards/rewards.component';
import { CreateBonusComponent } from './modules/admin/create-bonus/create-bonus.component';
import { NewsUpdateComponent } from './modules/admin/news-update/news-update.component';
import { NewsCreateComponent } from './modules/admin/news-create/news-create.component';
import { SelectStoreComponent } from './components/select-store/select-store.component';
import { StoreSelectDialogComponent } from './modals/store-select-dialog/store-select-dialog.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { CurrentRevenueComponent } from './components/current-revenue/current-revenue.component';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CongratsComponent } from './components/congrats/congrats.component';
import { S3ConnectDialogComponent } from './modals/s3-connect-dialog/s3-connect-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent, StoreSelectDialogComponent, CurrentRevenueComponent, CongratsComponent, S3ConnectDialogComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatSnackBarModule,
        MatChipsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,

        // Layout module of your application
        LayoutModule,

        // Translation module with loader
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        HttpClientModule,
    ],
    providers: [HttpClient],

    bootstrap: [AppComponent],
})
export class AppModule {}
