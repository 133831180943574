import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-current-revenue',
  templateUrl: './current-revenue.component.html',
  styleUrls: ['./current-revenue.component.scss']
})
export class CurrentRevenueComponent {
  @Output() revenueSelected = new EventEmitter<string>();
  revenueControl = new FormControl('');

  revenueOptions = [
    {
      revenue: "$0 USD (I'm just getting started)"
    },
    {
      revenue: "Up to $5,000 USD"
    },
    {
      revenue: "$5,000 USD to $50,000 USD"
    },
    {
      revenue: "$50,000 USD to $250,000 USD"
    },
    {
      revenue: "$250,000 USD to $1,000,000 USD"
    },
    {
      revenue: "$1,000,000 USD+"
    }
  ];

  constructor() {
    this.revenueControl.valueChanges.subscribe(() => {
      this.setOutput();
    });
  }

  setOutput() {
    this.revenueSelected.emit(this.revenueControl.value);
  }
}
