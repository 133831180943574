import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanMatch,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { RouteService } from 'app/services/route.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StoreGuard implements CanMatch {
    constructor(private _routeservice: RouteService, private _router: Router) {}
    canMatch(
        route: Route,
        segments: UrlSegment[]
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        if (this._routeservice.checkRouteHasSubdomain()) {
            return of(true);
        } else {
            const urlTree = this._router.parseUrl(`select-store`);
            return of(urlTree);
        }
    }
}
