import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { HttpRequestBuilder } from 'app/services/http-builder.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'app/environments/environment';

const AUTHENTICATION_PORT: string = environment.auth_port;
const DOMAIN = '.sellzzy.io';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private _httpClient: HttpClient;

    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _http: HttpRequestBuilder,
        private _cookie: CookieService,
        private _httpBackend: HttpBackend
    ) {
        this._httpClient = new HttpClient(_httpBackend);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        // localStorage.setItem('accessToken', token);
        this._cookie.set('access_token', token, 1, '/', DOMAIN);
        // TODO: Handle this domain name dynamically
    }

    get accessToken(): string {
        // return JSON.stringify(this._cookie.get('access_token')) ?? '';
        const token = this._cookie.get('access_token');
        if (token) {
            return token;
        }
        return '';
        // console.log(this._cookie.get('access_token'));
        // return localStorage.getItem('accessToken') ?? '';
    }

    set refreshToken(token: string) {
        // localStorage.setItem('refreshToken', token);
        this._cookie.set('refresh_token', token, 1, '/', DOMAIN);
    }
    get refreshToken(): string {
        const token = this._cookie.get('refresh_token');
        if (token) {
            return token;
        }
        // return JSON.stringify(this._cookie.get('refresh_token')) ?? '';
        // return localStorage.getItem('refreshToken') ?? '';
        return '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        delete credentials['rememberMe'];

        const request = this._httpClient.post(
            `${environment.apiUrl}:${environment.auth_port}/authenticate/`,
            credentials
        );

        return request.pipe(
            switchMap((res: any) => {
                console.log(res, 'auth service');
                // Store the access token in the local storage
                this.accessToken = res.access;
                this.refreshToken = res.refresh;
                this._authenticated = true;
                const { user_id, username, email } = AuthUtils.extractUser(
                    res.access
                );
                this._userService.user = {
                    id: user_id,
                    avatar: 'assets/images/avatars/brian-hughes.jpg',
                    name: username,
                    email: email,
                };

                return of(res);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token

        console.log(this.refreshToken);

        const request = this._http.post(
            AUTHENTICATION_PORT,
            'authenticate/token/refresh/',
            {
                refresh: this.refreshToken,
            }
        );

        return request.pipe(
            catchError(() =>
                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.access) {
                    this.accessToken = response.access;
                }

                const { user_id, uesrname, email } = AuthUtils.extractUser(
                    this.accessToken
                );

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = {
                    id: user_id,
                    avatar: 'assets/images/avatars/brian-hughes.jpg',
                    name: uesrname,
                    email: email,
                };

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');
        // localStorage.removeItem('refreshToken');

        this._cookie.delete('access_token', '/', DOMAIN);
        this._cookie.delete('refresh_token', '/', DOMAIN);

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    setTokens(access: string, refresh: string): void {
        this.accessToken = access;
        this.refreshToken = refresh;
        this._authenticated = true;
    }
}
