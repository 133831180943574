<div class="flex flex-col bg-white" style="width: 497px;">
    <div class="flex flex-col mb-8">
        <div class="font-semibold text-3xl">
            What is your current revenue?
        </div>
        <div class="font-normal text-lg" style="color: #71737C;">
            Welcome Back! Please enter your details.
        </div>
    </div>
    <div>
        <mat-chip-listbox [formControl]="revenueControl">
            <mat-chip-option *ngFor="let option of revenueOptions" [value]="option.revenue" class="font-normal text-base">{{ option.revenue }}</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
