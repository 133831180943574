<!-- select-store-dialog.component.html -->

<div class="flex flex-col bg-white p-6" style="width: 656px; height: 647px;">
    <div class="flex flex-row items-center justify-between" style="margin-bottom: 23px;">
        <div class="font-semibold text-3xl" *ngIf="data.primary==null">
            Set as primary store
        </div>
        <div class="font-semibold text-3xl" *ngIf="data.primary!=null">
            Change primary store
        </div>
        <button (click)="onClearClick()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <ng-container>
        <div class="store-item flex flex-row items-center justify-start p-3 rounded-lg gap-y-3 m-2"
            *ngFor="let store of stores" style="background-color: #FAFAFF;" (mouseenter)="onStoreHover(true, store)"
            (mouseleave)="onStoreHover(false, store)" (click)="onStoreClick(store)" aria-disabled="true">
            <div class="flex w-12 h-12 justify-center items-center m-2 text-white font-medium text-lg rounded-lg"
                style="padding:10px 8px; background-color: #019CDE;">
                {{ store.store_name | slice:0:2 }}
            </div>
            <div class="flex flex-row justify-between items-center w-full">
                <div class="flex flex-col">
                    <div class="font-semibold text-xl">
                        {{ store.store_name }}
                    </div>
                    <div class="font-normal text-sm" style="color: #9B9DA5;">
                        {{showStoreUrl(store)}}
                    </div>
                </div>
                <div *ngIf="primaryStore && primaryStore.id === store.id">
                    <mat-icon
                        style="width: 26px; height: 26px; justify-content: center; align-items: center; color: #0DC15F;">check_circle
                    </mat-icon>
                </div>
            </div>

            <div *ngIf="store.isHovered" class="store-border"></div>

        </div>
    </ng-container>
    <ng-template #loading>
        <mat-progress-spinner></mat-progress-spinner>
    </ng-template>
</div>
