import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'app/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpRequestBuilder {
    private url: string = environment.apiUrl;
    private headers: HttpHeaders = new HttpHeaders();
    private params: HttpParams = new HttpParams();
    private body: any = {};

    constructor(private http: HttpClient) {}

    private buildUrl(port: string, endpoint: string): string {
        return `${this.url}:${port}/${endpoint}`;
    }

    private handleError(error: any): Observable<never> {
        console.error('Error:', error);
        return throwError(error);
    }

    get(
        port: string = '8003',
        endpoint: string,
        data: any = {}
    ): Observable<any> {
        const url = this.buildUrl(port, endpoint);
        let params = new HttpParams();
        for (const key in data) {
            params = params.append(key, data[key]);
        }
        return this.http
            .get(url, { params: params })
            .pipe(catchError(this.handleError));
    }

    post(
        port: string = '8003',
        endpoint: string,
        body: any,
        options: any = {}
    ): Observable<any> {
        const url = this.buildUrl(port, endpoint);
        return this.http
            .post(url, body, options)
            .pipe(catchError(this.handleError));
    }

    put(
        port: string = '8003',
        endpoint: string,
        body: any,
        options: any = {}
    ): Observable<any> {
        const url = this.buildUrl(port, endpoint);
        return this.http
            .put(url, body, options)
            .pipe(catchError(this.handleError));
    }

    delete(
        port: string = '8003',
        endpoint: string,
        options: any = {}
    ): Observable<any> {
        const url = this.buildUrl(port, endpoint);
        return this.http
            .delete(url, options)
            .pipe(catchError(this.handleError));
    }
}