<div class="flex flex-col p-5">
    <div class="flex flex-row items-center justify-between mb-5">
        <div class="font-medium text-2xl">Connect to S3</div>
        <button (click)="close()">
            <mat-icon style="color: red;">cancel</mat-icon>
        </button>

    </div>
    <form [formGroup]="S3Form">
        <div class="flex flex-col mb-5">
            <div class="font-normal text-base">
                Access Key (required)
            </div>
            <div>
                Your S3 access key ID is a unique identifier for accessing your S3 storage. You can find it in your AWS account settings.
            </div>
            <div>
                <mat-form-field class="w-full">
                    <input matInput placeholder="Enter Your S3 access key ID" formControlName="access_key" required>
                    <mat-error *ngIf="S3Form.get('access_key').hasError('required') && S3Form.get('access_key').touched">
                        S3 access key ID is required
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-col mb-5">
            <div class="font-normal text-base">
                Secret Key (required)
            </div>
            <div>
                Your S3 secret key is a confidential key associated with your S3 access key ID. It is used for authentication and should be kept secure.
            </div>
            <div>
                <mat-form-field class="w-full">
                    <input matInput placeholder="Enter Your S3 secret key" formControlName="secret_key" required>
                    <mat-error *ngIf="S3Form.get('secret_key').hasError('required') && S3Form.get('secret_key').touched">
                        S3 secret key ID is required
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-col mb-5">
            <div class="font-normal text-base">
                Bucket (required)
            </div>
            <div>
                An S3 bucket is a container for storing your files. Enter the name of the bucket where you want to store your digital files.
            </div>
            <div>
                <mat-form-field class="w-full">
                    <input matInput placeholder="Enter Your S3 bucket name" formControlName="bucket" required>
                    <mat-error *ngIf="S3Form.get('bucket').hasError('required') && S3Form.get('bucket').touched">
                        Bucket name is required.
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-col mb-5">
            <div class="font-normal text-base">
                Host/Region (required)
            </div>
            <div>
                Specify the S3 host/region where your S3 bucket is located. It should match the region of your S3 storage.
            </div>
            <div>
                <mat-form-field class="w-full">
                    <input matInput placeholder="Enter Your S3 host/region" formControlName="host" required>
                    <mat-error *ngIf="S3Form.get('host').hasError('required') && S3Form.get('host').touched">
                        Host/Region is required.
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="flex flex-col mb-5">
            <div class="font-normal text-base">
                Validity (optional)
            </div>
            <div>
                Set the expiration time for the download links generated for your digital files. After the specified time, the links will no longer be accessible.
            </div>
            <div>
                <mat-form-field class="w-full">
                    <input matInput placeholder="Enter the link expiry time in seconds" formControlName="validity">
                </mat-form-field>
            </div>
        </div>
        <div>
                <button
                    [disabled]="S3Form.invalid"
                    class="px-8 py-4 text-white font-medium text-base"
                    (click)="connect()"
                    style="border-radius: var(--Redius-Redius---2, 8px);
                    background: var(--Colour-Primary, #7B00FF);">Connect</button>
        </div>
    </form>
</div>
