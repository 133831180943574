import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'app/environments/environment';
import { of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DropboxService {
    constructor(private _httpclient: HttpClient) {}

    getOauthUrl(data) {
        return this._httpclient
            .post(
                `${environment.apiUrl}:${environment.dropbox_port}/drop_box/connect/`,
                data
            )
            .pipe(
                switchMap((res) => {
                    return of(res);
                })
            );
    }
}
