import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RouteService {
    subDomainName: string;
    constructor() {
        this.getRouteSubDomain();
    }

    getRouteSubDomain(): void {
        let windowLocation = window.location.href;
        windowLocation = windowLocation.replace('http://', '');
        windowLocation = windowLocation.replace('https://', '');
        windowLocation = windowLocation.replace('www.', '');
        let windowLocationSplit = windowLocation.split('.');
        if (windowLocationSplit.length > 2) {
            this.subDomainName = windowLocationSplit[0];
        } else {
            this.subDomainName = null;
        }
    }
    checkRouteHasSubdomain(): boolean {
        return this.subDomainName === null ? false : true;
    }

    retrieveSubdomainName(): string {
        return this.subDomainName;
    }

    getSubdomainName() {
        this.getRouteSubDomain();
        return this.subDomainName;
    }
}
